@tailwind base;
@tailwind components;
@tailwind utilities;

*,

.html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}


#bg-video {
  position: relative;
  right: 0;
  left: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: 0;
}
/* General Styles */
body {
  margin: 0;
  padding: 0;
  font-family: "Arial", sans-serif;
  background-color: #f5f5f5;
}

.team-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

/* Member Cards */
.team-member {
  background: white;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, opacity 0.3s;
  cursor: pointer;
}

.team-member img {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 10px;
}

.team-member h3 {
  margin: 10px 0 5px;
  font-size: 1.25rem;
  color: #222;
}

.team-member p {
  font-size: 1rem;
  color: #666;
}

.team-member:hover {
  transform: scale(1.05);
}

.team-member.fade-out {
  opacity: 0.3;
  pointer-events: none;
}

/* Expanded Profile */
.expanded-profile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.profile-content {
  background: white;
  width: 90%;
  max-width: 1200px;
  display: flex;
  text-align: center;
  justify-content: center;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.profile-image {
  flex: 1;
}

.profile-image img {
  width: 100%;
  height: auto;
}

.profile-details {
  flex: 1.5;
  padding: 20px;
  text-align: left;
}

.profile-details h2 {
  margin: 0;
  font-size: 2rem;
  color: #222;
}

.profile-details h4 {
  margin: 10px 0;
  color: #555;
}

.profile-details p {
  margin: 10px 0;
  line-height: 1.6;
  color: #666;
}

.social-links {
  display: flex;
  gap: 15px;
  margin: 20px 0;
}

.social-links a {
  color: #333;
  font-size: 1.5rem;
  transition: color 0.3s;
}

.social-links a:hover {
  color: #0077b5; /* LinkedIn Blue */
}

.profile-details button {
  display: block;
  margin: 20px 0 0 auto;
  padding: 10px 20px;
  background: #333;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.profile-details button:hover {
  background: #555;
}

/* .bg {
  background-image: url("../../public/Untitled-1\ copy.jpg");
  background-size: cover;
  background-position: center;
}

.contact {
  background-image: url("../../public/Untitled-1\ copy.jpg");
  background-size: cover;
  background-position: center;
}

.side {
  background-image: url("../asset/sideimg.jpg");
  background-size: cover;
  background-position: center;
  margin-top: -180px;
  z-index: -1;
}
.mountains {
  background-image: url("../asset/mountains.jpg");
  background-size: cover;
  background-position: center;
  margin-bottom: 100px;
} */

.fixed-section {
  position: fixed;
  top: 0;
  z-index: 100; /* Adjust the z-index to ensure it's above other content */
}

/* Style for the content below the fixed section */
.content {
  padding: 20px; /* Add padding to create space below the fixed section */
}


.cool-link {
  display: inline-block;
  color: #000;
  text-decoration: none;
}

.cool-link::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #000;
  transition: width 0.3s;
}

.cool-link:hover::after {
  width: 100%;
  /* //transition: width .3s; */
}

.cool-links {
  display: inline-block;
  color: #403F68;
  text-decoration: none;
}

.cool-links::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #403F68;
  transition: width 0.3s;
}

.cool-links:hover::after {
  width: 100%;
  /* //transition: width .3s; */
}

#simple-tab-1:focus {
  color: black !important;
}
#simple-tab-0:focus {
  color: black !important;
}

.css-1aquho2-MuiTabs-indicator {
  background-color: #1d6e0c !important;
}

#myCheckbox {
  appearance: none; /* Remove default checkbox appearance */
  width: 30px;
  height: 30px;
  border-radius: 50%; /* Make the box circular */
  border: 3px solid #403F68; /* Add a border */
  outline: none; /* Remove the outline when the checkbox is focused */
  cursor: pointer; /* Add a pointer cursor */
}

/* Style the checkbox when it's checked */
#myCheckbox:checked {
  background-color: #403F68;
}

#myCheckboxs {
  appearance: none; /* Remove default checkbox appearance */
  width: 30px;
  height: 30px;
  border-radius: 50%; /* Make the box circular */
  border: 3px solid #403F68; /* Add a border */
  outline: none; /* Remove the outline when the checkbox is focused */
  cursor: pointer; /* Add a pointer cursor */
}

/* Style the checkbox when it's checked */
#myCheckboxs:checked {
  background-color: #100dc2;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #3f683f !important;
}

.hvr-bounce-to-top {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
.hvr-bounce-to-top:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #403F68;
  border-radius: 50px;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-bounce-to-top:hover,
.hvr-bounce-to-top:focus,
.hvr-bounce-to-top:active {
  color: white;
}
.hvr-bounce-to-top:hover:before,
.hvr-bounce-to-top:focus:before,
.hvr-bounce-to-top:active:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

@media (max-width: 575.98px) {
  #myCheckboxs {
    height: 22px !important;
  }
}

.slider {
  width: 99.2vw;
  height: 85vh;
  overflow: hidden;
  position: relative;
}

.slider .wrapper {
  height: 100vh;
  transition: .5s all linear;
  will-change: transform;
}

.slider .arrows {
  position: absolute;
  top: 50%;
  background: none;
  height: 60px;
  border: 0;
  cursor: pointer;
  transition: ease .3s all;
  outline: none;
}

.slider .arrows.prev {
  left: 0;
}

.slider .arrows.prev:hover {
  opacity: .7;
  left: -10px;
}

.slider .arrows.next {
  right: 0;

}

.slider .arrows.next:hover {
  right: -10px;
  opacity: .7;
}

.slider .dots-container {
  height: auto;
  margin: 0;
  padding: 0;
  position: absolute;
  width: auto;
  text-align: center;
  left: 50%;
  bottom: 9px;
  transform: translateX(-50%);
  z-index: 10;
  list-style-type: none;
}

.slider .dots-container li {
  display: inline-block;
  padding: 5px;
}

.slider .dots-container li.active button {
  color: #403F68;
}

.slider .dots-container button {
  color: #fff;
  background-color: transparent;
  border: none;
}

.slider .dots-container button:hover {
  text-decoration: none;
  opacity: .7;
  cursor: pointer;
}

.slider .toggle-play {
  background: transparent;
  border: none;
  height: auto;
  position: absolute;
  width: auto;
  right: 5%;
  bottom: 9px;
  color: #3d3d3d;
  z-index: 1000000;
}

.slider .toggle-play:hover {
  text-decoration: none;
  opacity: .7;
  cursor: pointer;
}

.slider .each-slide {
  width: 100vw;
  height: 100vh;
  float: left;
  line-height: 100vh;
  text-align: center;
  background-size: cover;
  background-position: center center;
  background-color: transparent;
}

/* Preloader.css */
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #111316; /* Background color for the preloader */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader {
  border: 8px solid #f3f3f3; /* Light gray border */
  border-top: 8px solid #403F68; /* Blue border */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* Container for the timeline */
.timeline-container {
  position: relative;
}

.timeline {
  position: relative;
  padding: 20px 0;
}

/* Timeline item structure */
.timeline-item {
  display: flex;
  align-items: center;
  padding: 20px 0;
  width: 100%;
  position: relative;
}

/* Left-side items */
.left-item {
  justify-content: flex-end;
  text-align: right;
  padding-right: 50%;
}

/* Right-side items */
.right-item {
  justify-content: flex-start;
  text-align: left;
  padding-left: 50%;
}

/* Icon in the center */
.timeline-item .timeline-icon {
  background: linear-gradient(to right, #1FB689, #1FB689);
  width: 55px;
  height: 55px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  position: absolute;
  left: calc(50% - 27px); /* Center the icon in the middle */
  z-index: 1;
}

.timeline-item .timeline-content {
  background: #F0F5F6;
  max-width: 320px;
  height: 135px;
  padding: 25px 25px;
  position: relative;
  z-index: 2;
}

/* Timeline line */
.timeline::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  width: 4px;
  background: #1FB689;
  transform: translateX(-50%);
  z-index: 0;
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
  .timeline-item {
    justify-content: center;
    text-align: center;
    padding-right: 0;
    padding-left: 0;
  }

  .timeline-item .timeline-icon {
    left: 50%;
    transform: translateX(-50%);
  }

  .timeline-item .timeline-content {
    text-align: center;
  }
}

/* defines the animation */
@keyframes fadeInUp {
    from { 
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  .first {
    opacity: 0; 
    animation: fadeInUp 1s ease-in-out 0s forwards;
    animation-delay: 0.5s;
}

.second {
    opacity: 0; 
    animation: fadeInUp 1s ease-in-out 0s forwards;
    animation-delay: 1s;
}

.third {
    opacity: 0;
    animation: fadeInUp 1s ease-in-out 0s forwards;
    animation-delay: 2s;

}

.fourth {
    opacity: 0;
    animation: fadeInUp 1s ease-in-out 0s forwards;
    animation-delay: 3s;
}

.five {
  opacity: 0;
  animation: fadeInUp 1s ease-in-out 0s forwards;
  animation-delay: 4s;
}
