@tailwind base;
@tailwind components;
@tailwind utilities;

*,

.html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

.first {
  opacity: 0; 
  animation: fadeInUp 1s ease-in-out 0s forwards;
  animation-delay: 0.5s;
}

.second {
  opacity: 0; 
  animation: fadeInUp 1s ease-in-out 0s forwards;
  animation-delay: 1s;
}

.third {
  opacity: 0;
  animation: fadeInUp 1s ease-in-out 0s forwards;
  animation-delay: 2s;

}

.fourth {
  opacity: 0;
  animation: fadeInUp 1s ease-in-out 0s forwards;
  animation-delay: 3s;
}

.five {
opacity: 0;
animation: fadeInUp 1s ease-in-out 0s forwards;
animation-delay: 4s;
}

/* General Styles */
body {
  margin: 0;
  padding: 0;
  font-family: "Arial", sans-serif;
  background-color: #f5f5f5;
}

.team-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

/* Member Cards */
.team-member {
  background: white;
  padding: 10px;
  transition: transform 0.3s, opacity 0.3s;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.team-member img {
  width: 100%;
  margin-bottom: 10px;
}

.team-member p {
  font-size: 1rem;
  color: #666;
}

.team-member:hover {
  transform: scale(1.05);
}

.team-member.fade-out {
  opacity: 0.3;
  pointer-events: none;
}

/* Expanded Profile */
.expanded-profile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column; /* Adjusted for mobile responsiveness */
  align-items: center;
  justify-content: center;
  z-index: 10;
  animation: fadeIn 0.3s ease;
  overflow-y: auto; /* Handle long profiles on smaller screens */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.profile-content {
  background: white;
  width: 90%;
  max-width: 1200px;
  display: flex;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

@media (min-width: 768px) {
  .profile-content {
    flex-direction: row;
  }
}

.profile-image {
  flex: 1;
}

.profile-image img {
  width: 100%;
  height: auto;
  object-fit: cover; /* Ensure good scaling on different devices */
}

.profile-details {
  flex: 2;
  padding: 20px;
  text-align: left;
}

.profile-details p {
  white-space: pre-line;
  margin: 10px 0;
  line-height: 1.5;
  color: #666;
  font-size: 1rem;
}


.social-links {
  display: flex;
  gap: 15px;
  margin: 20px 0;
  flex-wrap: wrap; /* Ensure links wrap on small screens */
}

.social-links a {
  color: #333;
  font-size: 1.5rem;
  transition: color 0.3s;
}

.social-links a:hover {
  color: #0077b5; /* LinkedIn Blue */
}

.profile-details button {
  display: block;
  margin: 20px 0 0 auto;
  padding: 10px 20px;
  background: #333;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.profile-details button:hover {
  background: #555;
}

/* Mobile Adjustments */
@media (max-width: 480px) {
  .team-grid {
    grid-template-columns: 1fr; /* Single column layout for smaller screens */
  }

  .profile-content {
    flex-direction: column;
  }
}